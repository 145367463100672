
import { useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import { ref, defineComponent, onMounted, watch, computed } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";

import { useRouter } from "vue-router";
import { Actions, Modules } from "@/store/enums/StoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
//import { formatDateTimePHP } from "@/core/helpers/date";
import SubmitButton from "@/components/SubmitButton.vue";

import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";

export default defineComponent({
  name: "ChangePassword",

  components: { SubmitButton },

  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);

    const schema = yup.object({
      password: yup
        .string()
        .min(6)
        .max(15)
        .required()
        .matches(
          /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/,
          "Please choose a stronger password"
        )
        .label("Password"),
      cpassword: yup
        .string()
        .required()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    const { meta, errors, handleSubmit, isSubmitting, setErrors } = useForm({
      initialValues: {
        password: "",
        cpassword: "",
      },
      validationSchema: schema,
    });
    //const { value: id } = useField("id");
    const { value: password } = useField("password");
    const { value: cpassword } = useField("cpassword");

    const onSubmit = handleSubmit((values, { resetForm }) => {
      return new Promise<void>((resolve) => {
        store
          .dispatch(Actions.CHANGE_PASSWORD, { password: values.password })

          .then(() => {
            Swal.fire({
              text: store.getters["getSuccessMessage"],
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              resetForm();
              router.push({ name: "changePassword" });
            });
          })
          .catch(() => {
            setErrors(store.getters["getErrors"]);

            Swal.fire({
              //text: store.getters.getErrors[error],
              text: "Failed to Change Password. Please fix the errors.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          })
          .finally(() => {
            resolve();
          });
      });
    });

    onMounted(() => {
      setCurrentPageTitle("Change Password");
      // router.push({ name: "kt-aside-primary" });
    });

    return {
      submitButton,
      meta,
      errors,
      onSubmit,
      isSubmitting,
      schema,
      password,
      cpassword,
    };
  },
});
